var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "sygniFlagPicker",
    staticClass: "sygni-flag-picker"
  }, [_c('div', {
    staticClass: "sygni-flag-picker__inner"
  }, [_c('div', {
    staticClass: "sygni-flag-picker__container"
  }, [_vm.selectedFlagsFiltered == 0 ? _c('sygni-flag') : _vm._l(_vm.selectedFlagsToShow, function (flag) {
    return _c('sygni-flag', {
      key: flag.label,
      attrs: {
        "color": flag.color
      }
    });
  }), _vm.counter ? _c('span', {
    staticClass: "sygni-flag-picker__counter"
  }, [_vm._v("+" + _vm._s(_vm.counter))]) : _vm._e()], 2), _c('div', {
    staticClass: "sygni-flag-picker__dropdown-btn",
    on: {
      "click": function click($event) {
        _vm.showDropdown = true;
      }
    }
  }, [_c('img', {
    staticClass: "arrow-up",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })])]), _c('b-popover', {
    attrs: {
      "target": function target() {
        return _vm.$refs.sygniFlagPicker;
      },
      "placement": "bottom",
      "triggers": "hover",
      "customClass": "sygni-flag-dropdown"
    }
  }, [_c('div', {
    staticClass: "sygni-flag-dropdown__inner"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "sygni-flag-dropdown__inner-body"
  }, [_vm.selectedFlags.length == 0 ? _c('div', {
    staticClass: "sygni-flag-dropdown__option sygni-flag-dropdown__option--placeholder"
  }, [_vm._v("There are no flags to display")]) : _vm._e(), _vm._l(_vm.selectedFlags, function (flag) {
    return _c('div', {
      key: flag.label,
      staticClass: "sygni-flag-dropdown__option",
      on: {
        "click": function click($event) {
          return _vm.toggleFlag(flag);
        }
      }
    }, [_c('sygni-flag', {
      attrs: {
        "color": flag.color
      }
    }), _c('p', {
      staticClass: "sygni-flag-dropdown__option-text"
    }, [_vm._v(_vm._s(flag.name))]), _c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "value": flag.value
      }
    })], 1);
  })], 2), _c('div', {
    staticClass: "sygni-flag-dropdown__btn",
    on: {
      "click": function click($event) {
        return _vm.$emit('openFlagManagementModal');
      }
    }
  }, [_vm._v("Manage")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }