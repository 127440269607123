























































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import SygniPlainInput from '@/components/inputs/SygniPlainInput.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniSquareButton from '@/components/buttons/SygniSquareButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import { BTooltip } from 'bootstrap-vue';
import draggable from 'vuedraggable';
import { create, all } from 'mathjs'
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { required } from 'vuelidate/lib/validators';

const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: { SygniCheckbox, SygniRectButton, SygniPlainInput, SygniRoundedButton, SygniDatePicker, SygniSquareButton, SygniLinkButton, SygniModal, SygniSelect, SygniTextArea, draggable, simplebar, BTooltip }
})
export default class ScheduleTable extends Vue {
  @Prop({ default: null }) productId: string | null;
  @Prop({ default: false }) editMode: boolean;
  @Prop({ default: null }) originalInterestTotalGross: number;
  @Prop({ default: null }) originalInterestTotalTax: number;
  @Prop({ default: null }) originalInterestTotalNet: number;
  @Prop({ default: null }) product: any;
  @Prop() repaymentSchedule: any;
  selectedProducts: string[] = [];
  bulkOptionsMarginTop: number = 0;
  selectAllProductsOption: boolean = false
  updateDescription: boolean = false

  isModalLoading: boolean = false
  description: string | null = null
  paymentStatus: string | null = null
  detailModalIds: string[] = []

  math!: any;

  get netTotal() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if(el.interestAmountNet?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountNet.value)));
      }
    });

    return total ? total : 0;
  }

  get taxTotal() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if(el.interestTax?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestTax.value)));
      }
    });

    return total ? total : 0;
  }

  get grossTotal() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if(el.interestAmountGross?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountGross.value)));
      }
    });

    return total ? total : 0;
  }

  get capitalTotalGross() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'capital' && el.interestAmountGross?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountGross.value)));
      }
    });

    return total ? total : 0;
  }

  get interestTotalGross() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'interest' && el.interestAmountGross?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountGross.value)));
      }
    });

    return total ? total : 0;
  }

  get capitalTotalNet() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'capital' && el.interestAmountNet?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountNet.value)));
      }
    });

    return total ? total : 0;
  }

  get interestTotalNet() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'interest' && el.interestAmountNet?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountNet.value)));
      }
    });

    return total ? total : 0;
  }

  get capitalTotalTax() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'capital' && el.interestTax?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestTax.value)));
      }
    });

    return total ? total : 0;
  }

  get interestTotalTax() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'interest' && el.interestTax?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestTax.value)));
      }
    });

    return total ? total : 0;
  }

  get currency() {
    if(this.repaymentSchedule?.items) {
      return this.repaymentSchedule?.items[0]?.interestAmountGross?.currency;
    }

    return '';
  }

  get paymentDictionaries() {
    return this.$store.getters['products/getDictionary'] ? this.$store.getters['products/getDictionary'] : {};
  }

  get paymentStatuses() {
    return this.paymentDictionaries?.paymentStatus ? this.paymentDictionaries.paymentStatus.map((el: any) => {
      return { label: this.$options.filters.capitalizeFirstLetter(el.label), value: el.value }
    }) : []
  }

  checkMove(e: any) {
    return this.isDraggable(e?.draggedContext);
  }

  isDraggable(context: any) {
    const { index, futureIndex } = context
    
    return !(this.repaymentSchedule.items[index].paymentType === 'capital' || this.repaymentSchedule.items[futureIndex].paymentType === 'capital');
  }

  selectAllProducts() {
    this.$nextTick(() => {
      if (this.selectAllProductsOption) {
        const allProductIds = this.repaymentSchedule?.items.map((el: any) => el.id)

        this.selectedProducts = allProductIds

        const selectedRowEl = (this.$refs.table as any).querySelector('.table tbody tr:nth-of-type(1)');
        this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      } else {
        this.clearTableSelection()
      }
    })
  }

  selectProduct(id: string, index: number) {
    this.$nextTick(() => {
      if (this.selectedProducts.includes(id)) {
        const index = this.selectedProducts.findIndex((el: string) => el === id)
        this.selectedProducts.splice(index, 1)
      } else {
        this.selectedProducts.push(id)
      }

      const selectedRowEl = (this.$refs.table as any).querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
    })
  }

  isSelected(id: string) {
    return this.selectedProducts.includes(id) ? true : false
  }

  clearTableSelection() {
    this.selectedProducts = []
  }

  openDetailsModal(ids: string[], paymentStatus: string | null = null, description: string | null = null, updateDescription: boolean) {
    this.$v?.$reset();
    this.updateDescription = updateDescription
    this.detailModalIds = ids
    this.paymentStatus = paymentStatus
    this.description = description
  }

  closeDetailsModal() {
    this.detailModalIds = []
  }

  async updatePaymentData() {
    this.$v?.$touch()

    if(!this.$v?.$error) {
      const items = this.repaymentSchedule.items.filter((el: any) => this.detailModalIds.includes(el.id));
      this.isModalLoading = true
      try {
        if (this.paymentStatus) {
          await this.$store.dispatch('products/updatePaymentStatuses', { items: this.detailModalIds.map((id: string) => { return { productId: this.productId, paymentId: id } }), status: this.paymentStatus })
          items.forEach((item: any) => {
            item.paymentStatus = this.paymentStatus
          })
        }

        if (this.updateDescription) {
          await this.$store.dispatch('products/updatePaymentDescriptions', { items: this.detailModalIds.map((id: string) => { return { productId: this.productId, paymentId: id } }), description: this.description ? this.description : null })
          items.forEach((item: any) => {
            item.description = this.description
          })
        }

        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Payments updated.'
        })
        this.closeDetailsModal()
      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }
      this.isModalLoading = false
    }
  }

  get interestMethod() {
    return this.product?.data?.interestCalculationMethod
  }

  get capitalGainsTax() {
    return this.product?.capitalGainsTax
  }

  calculateInterestTax(value: number) {
    if (!this.capitalGainsTax) {
      return 0
    }

    return math.round(math.bignumber(math.multiply(math.number(value), math.number(0.19))), this.interestMethod === '30/360' ? 2 : 0)
  }

  calculateInterestNet(value: number) {
    if (!this.capitalGainsTax) {
      return value
    }
    
    return math.round(math.bignumber(math.subtract(math.number(value), math.number(this.calculateInterestTax(value)))), 2)
  }

  handleValueInputGrossBlurEvent(e: any, item: any, cb?: any) {
    const value = Number(Number(String(e.target.value).replaceAll(' ', '').replaceAll(',', '.')).toFixed(2));
    item.interestAmountGross.value = value;

    item.interestTax.value = this.calculateInterestTax(value)
    item.interestAmountNet.value = this.calculateInterestNet(value)

    if (cb) {
      cb();
    }
  }

  handleValueInputBlurEvent(e: any, item: any, cb?: any) {
    const value =  Number(Number(String(e.target.value).replaceAll(' ', '').replaceAll(',', '.')).toFixed(2));
    item.value = value;

    if(cb) {
      cb();
    }
  }

  removeRepaymentScheduleRow(index: number) {
    this.repaymentSchedule.items.splice(index, 1);
  }

  addRepaymentScheduleRow() {
    this.repaymentSchedule.items.push({
      id: null,
      interestAmountGross: {
        value: 0,
        currency: this.currency,
      },
      interestAmountNet: {
        value: 0,
        currency: this.currency,
      },
      interestTax: {
        value: 0,
        currency: this.currency,
      },
      paymentType: 'interest',
      interestPeriodFrom: null,
      interestPeriodTo: null,
      paymentStatus: 'unpaid',
    })
  }

  statusClass(status: string) {
    switch (status) {
      case ('unpaid'): return 'danger';
      case ('paid'): return 'primary';
      case ('scheduled'): return 'success';
      case ('annex'): return 'lead';
    }

    return 'black';
  }

  calculateTotals(item: any) {
    let vat = 0.81;

    const netValue = parseFloat(item.interestAmountNet.value.toString().replace(/\s/g, '').replace(/,/g, '.'));
    const vatValue = this.getRoundedValue((netValue * vat).toString());
    const grossValue = this.getRoundedValue((netValue + (netValue * vat)).toString());

    item.interestTax.value = vatValue;
    item.interestAmountGross.value = grossValue;
  }

  calculateGross(item: any) {
    const netValue = parseFloat(item.interestAmountNet.value.toString().replace(/\s/g, '').replace(/,/g, '.'));
    const vatValue = parseFloat(item.interestTax.value.toString().replace(/\s/g, '').replace(/,/g, '.'));

    item.interestAmountGross.value = this.getRoundedValue((netValue + vatValue).toString());
  }

  getRoundedValue(value: any) {
    let roundTo = 2

    const decimals = value.split('.')[1];

    const roundAvailable = decimals !== undefined && decimals.length > 2;
    if (roundAvailable) {
      const roundBy = decimals[2];

      if (roundBy > 4) {
        value = Number(value).toFixed(2).toString()
      }
    }

    if (value === '') {
      return value;
    }

    value = value.replace(/,/g, '.');

    value = value.replace(/ /g, '');
    value = value.replace(/ /g, '');
    value = Number(value);

    if (!value) {
      value = 0;
    }

    const dotIndex = value.toString().indexOf('.');

    if (dotIndex === -1) {
      value = Number(value).toFixed(roundTo);
    } else {
      value = value.toPrecision(dotIndex + 1 + roundTo);
      value = value.toString().substring(0, dotIndex + roundTo + 1);

      const decimalsLength = value.split('.')[1].length;

      if (decimalsLength < roundTo) {
        const fillAmount = roundTo - decimalsLength;
        value += '0'.repeat(fillAmount);
      }
    }

    value = value.replace(/\./g, ',');

    let splitted = value.split('.');
    splitted[0] = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return splitted.join('.');
  }

  validations() {
    return {
      paymentStatus: { required }
    }
  }
}
