var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "attachmentModal",
    class: ['attachment-modal', _vm.isPdf ? 'attachment-modal--no-padding' : '', "attachment-modal--".concat(_vm.size)]
  }, [_c('div', {
    staticClass: "attachment-modal__bg",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "attachment-modal__box"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "attachment-modal__actions"
  }, [_vm.download ? _c('button', {
    staticClass: "attachment-modal__action attachment-modal__action--download",
    on: {
      "click": function click($event) {
        return _vm.$emit('download');
      }
    }
  }, [_vm._v("Download")]) : _vm._e(), _c('button', {
    staticClass: "attachment-modal__action attachment-modal__action--close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "attachment-modal__inner"
  }, [_c('div', {
    staticClass: "attachment-modal__body"
  }, [_vm.isPdf ? _c('preview-doc', {
    attrs: {
      "simple": true,
      "selectedDocument": _vm.attachmentPath
    }
  }) : _c('editor-component', {
    ref: "editorComponentEl",
    attrs: {
      "content": _vm.agreementTemplateContent,
      "editable": false,
      "simple": true,
      "issuer": _vm.issuer,
      "investor": _vm.investor,
      "product": _vm.product,
      "investorDetails": _vm.investorDetails,
      "legalEntityInvestorData": _vm.legalEntityInvestorData
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }