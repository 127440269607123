


















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';
import SygniCircleButton from '@/components/buttons/SygniCircleButton.vue';
import SygniFlagPicker from '@/components/inputs/SygniFlagPicker.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import {BSpinner} from "bootstrap-vue";
import pdf from 'pdfvuer';
import { jsPDF } from 'jspdf';
import { ContextData } from '../store';

@Component({
  components: { pdf, BSpinner, SygniCircleButton, SygniFlagPicker, SygniRoundedButton },
})
export default class PreviewDoc extends Vue {
  @Prop() enabledActions?: Array<string> | undefined = undefined;
  @Prop() selectedDocument?: string;
  @Prop() activeDocument: any;
  @Prop() contextData?: ContextData;
  @Prop({default: false}) simple: boolean;
  @Prop({default: false}) shouldSend: boolean;
  @Prop({default: false}) isUltimatelyFailed: boolean;
  @Prop({default: true}) checkViewport: boolean;
  @Prop() sendToApprovalErrors: string[];

  keyboardHandler?: any = undefined;
  zoomControl: boolean = false;
  isLoading: boolean = false;
  showNote: boolean = false;
  pdfData?: any = undefined;
  pages: number = 0;
  scale: number = 1;
  dynamicKey: number = 0;
  rotation: number = 0;
  minHeight: string = '0px';

  get sendToApprovalErrorsText() {
    return this.sendToApprovalErrors?.join(', ');
  }

  sendFn(): void {
    this.$emit('send');
  }

  editFn(): void {
    if (!this.isUltimatelyFailed) {
      this.$emit('edit');
    }
  }

  bookFn(): void {
    this.$emit('bookDocument')
  }

  generatePaymentPackageFn(): void {
    this.$emit('generatePaymentPackage')
  }

  downloadFn(): void {
    this.$emit('download');
  }

  prevFn(): void {
    this.$emit('prev');
  }

  nextFn(): void {
    this.$emit('next');
  }

  deleteFn(): void {
    this.$emit('delete');
  }

  closeDocument() {
    this.dynamicKey = Math.random();
    this.$emit('close');
  }

  approveDocument() {
    this.$emit('approve');
  }

  rejectDocument() {
    this.$emit('reject');
  }

  zoomIn(speed: number) {
    if(this.scale < 1.5) {
      this.scale += speed ? speed : 0.05;
    }
  }

  zoomOut(speed: number) {
    if(this.scale > 0.5) {
      this.scale -= speed ? speed : 0.05;
    }
  }

  rotate(degrees: number) {
    this.rotation += degrees;
  }

  getTooltipStatusMessage(item: any) {
    if (item?.status == 'rejected') return item?.note ? item.note : '';

    return item?.status == 'failed' ? item.errorMessage : '';
  }

  getDocumentStatusClassName(status: string): string {
    let className = 'default';
    switch (status?.toLowerCase()) {
      case ('processed'):
        className = 'default';
        break;
      case ('approved'):
        className = 'success';
        break;
      case ('sent-to-approval'):
      case ('booking'):
        className = 'warning';
        break;
      case ('uploaded'):
      case ('processing'):
      case ('booked'):
        className = 'primary';
        break;
      case ('failed'):
      case ('rejected'):
      case ('rejected-by-operator'):
      case ('cancelled'):
      case ('canceled'):
        className = 'danger';
        break;
      default:
        className = 'default';
    }

    return className;
  }

  statusText(status: string): string {
    switch(status) {
      case('approved'):
        return 'Approved';
      case('processing'):
        return 'Processing';
      case('uploaded'):
        return 'Uploaded';
      case('processed'):
        return 'Processed';
      case('sent-to-approval'):
        return 'Sent for approval';
      case('rejected'):
        return 'Rejected';
      case('failed'):
        return 'Failed';
      case('booked'):
        return 'Booked';
      case('canceled'):
        return 'Canceled';
    }
    return status;
  }

  setViewport() {
    this.scale = 1;

    this.$nextTick(() => {
      const pageSize = { width: document.querySelector('.page')?.clientWidth, height: document.querySelector('.page')?.clientHeight };
      const documentWindowSize = { width: document.querySelector('.preview-doc__container')?.clientWidth, height: document.querySelector('.preview-doc__container')?.clientHeight };

      
      if(this.simple || !this.checkViewport) {
        if(pageSize.width != 0 && pageSize.height != 0 && pageSize.width != documentWindowSize.width) {
          const scale = documentWindowSize.width / (pageSize.width + 60);
          this.scale = scale;
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.minHeight = `${document.querySelector('.preview-doc__container')?.clientHeight ? document.querySelector('.preview-doc__container').clientHeight : '0'}px`;
          }, 50)
        })
      } else {
        if(pageSize.width != 0 && pageSize.height != 0 && pageSize.height != documentWindowSize.height) {
          const scale = documentWindowSize.height / pageSize.height;
          this.scale = scale;
        }
      }
    });
  }

  handleNote(state: boolean) {
    this.showNote = state;
  }

  enableZoom() {
    document.querySelector('.app-content').classList.add('blocked');
    this.zoomControl = true;
  }

  disableZoom() {
    document.querySelector('.app-content').classList.remove('blocked');
    this.zoomControl = false;
  }

  handleScrollControl(e: WheelEvent) {
    if(this.operatingSystem == 'MacOS') {
      if(e.metaKey) {
        if(e.deltaY <= 0) {
          this.zoomIn(0.05);
        } else {
          this.zoomOut(0.05);
        }
      }
    } else {
      if(e.altKey) {
        if(e.deltaY <= 0) {
          this.zoomIn(0.05);
        } else {
          this.zoomOut(0.05);
        }
      }
    }
  }

  getPdf(pdfUrl: string) {
    this.isLoading = true;
    this.rotation = 0;
    this.pdfData = (pdf as any).createLoadingTask(pdfUrl);

    this.pdfData.then((doc: any) => {
      this.pages = doc.numPages;
      this.dynamicKey = Math.random();

      setTimeout(() => {
        this.isLoading = false;
        this.setViewport();
      }, 50);
    }).catch(() => {
      this.isLoading = false;
      this.$notify({
        title: 'Error',
        text: 'Something went wrong. Please try again later.',
        type: 'error',
        duration: 2500,
      })
    });
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get operatingSystem() {
    let os = 'unknown';
    if (navigator.userAgent.indexOf("Win") != -1) os = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) os = "MacOS";
    if (navigator.userAgent.indexOf("X11") != -1) os = "UNIX";
    if (navigator.userAgent.indexOf("Linux") != -1) os = "Linux";

    return os;
  }

  created() {
    this.keyboardHandler = (e: KeyboardEvent) => {
      if(this.operatingSystem == 'MacOS') {
        if(e.key == 'Meta' && e.code == 'MetaLeft' && e.metaKey) {
          this.enableZoom();
        } else {
          this.disableZoom();
        }
      } else {
        if(e.altKey) {
          this.enableZoom();
        } else {
          this.disableZoom();
        }
      }
    };

    this.$root.$el.addEventListener('keydown', this.keyboardHandler);
    this.$root.$el.addEventListener('keyup', this.keyboardHandler);
  }

  unmounted() {
    this.$root.$el.removeEventListener('keydown', this.keyboardHandler);
    this.$root.$el.removeEventListener('keyup', this.keyboardHandler);
  }

  beforeDestroy() {
    this.$root.$el.removeEventListener('keydown', this.keyboardHandler);
    this.$root.$el.removeEventListener('keyup', this.keyboardHandler);
  }

  loadPdf() {
    const isPdf = this.selectedDocument.toLowerCase().indexOf('.pdf') != -1 ? true : false;
    
    if(!isPdf) {
      this.isLoading = true;
      this.generateImagePdf(this.selectedDocument).then((doc: any) => {
        const resp = doc.output('bloburl');
        this.getPdf(resp);
      });
    } else {
      this.getPdf(this.selectedDocument);
    }
  }

  mounted() {
    if(this.simple && this.selectedDocument) {
      this.loadPdf();
    }
  }

  generatePdf(imageData: string){
    /** this function receives the image param and creates the pdf with it**/
      const doc = new jsPDF();
      doc.addImage(imageData, "JPEG", 0, 0, 210, 297);

      return doc;
  }

  getBase64Image(url: string) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.crossOrigin = "anonymous";

      const $this = this;

      img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/jpeg');
        const doc = $this.generatePdf(dataURL);

        resolve(doc);
      }
    });
  }

  async generateImagePdf(url: string) {
    const promise = await this.getBase64Image(url);

    return promise;
  }

  @Watch('selectedDocument') onValueChange(): void {
    if(this.selectedDocument) {
      this.minHeight = '0px';
      this.loadPdf();
    }
  }
}
