
























































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniLinkButton from "@/components/buttons/SygniLinkButton.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import AttachmentModal from '@/modules/genprox/modules/fund/modules/fundraising/modules/templates/components/AttachmentModal.vue';
import {mapState} from "vuex";
import SygniTableLegacy from "@/components/table/SygniTableLegacy.vue";
import breakpoints from '@/plugins/breakpoints';
import { Prop } from 'vue-property-decorator';
import Utils from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniLinkButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, AttachmentModal },
    computed: mapState('investors', {
      itemsLength: (state: any) => state.investorsLength,
    }),
})
export default class AttachmentsTable extends SygniTableLegacy<MarketplaceItem> {
  @Prop() tableItems?: [];
  @Prop({ default: false }) showDelete: boolean;
  @Prop({ default: false }) customDelete: boolean;
  showAttachmentPreviewModal: boolean = false;
  breakpoints = breakpoints;
  
  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'fileName', sortable: false, label: 'Name', thClass: 'th-cell text-left' },
    { key: 'actions', sortable: false, label: 'Actions', borderless: true, thClass: 'th-cell text-right' },
  ];

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName);
  }

  hasPreview(file: any) {
    return file?.fileName?.toLowerCase().indexOf('.pdf') != -1 || 
      file?.fileName?.toLowerCase()?.indexOf('.png') != -1 ||
      file?.fileName?.toLowerCase()?.indexOf('.jpg') != -1 ||
      file?.fileName?.toLowerCase()?.indexOf('.jpeg') != -1
      ? true : false;
  }

  closeAttachmentPreviewModal() {
    this.showAttachmentPreviewModal = false;
  }

  openAttachmentPreview(attachment: any) {
    if (this.hasPreview(attachment)) {
      this.selectedAttachment = attachment;
      this.showAttachmentPreviewModal = true;
    }
  }

  async deleteAttachment(id: string) {
    if (this.customDelete) {
      this.$emit('removeItem', id)
    } else {
      this.isBusy = true;
  
      try {
        await this.$store.dispatch('accounting/deleteAttachment', { documentId: this.$route.params.id, attachmentId: id });
        this.$emit('removeItem', id);
        this.isBusy = false;
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
        this.isBusy = false;
      }
    }
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    setTimeout(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';
      switch(this.sortBy){
        case('name'):
          sortBy = 'investmentClientName';
          break;
        case('status'):
          sortBy = 'investmentClientStatus';
          break;
        case('sourceName'):
          sortBy = 'investmentClientSourceName';
          break;
        case('created'):
          sortBy = 'investmentClientCreated';
          break;
      }
      this.sortingQuery = `sort[]=${sign}${sortBy}`;
      this.$store.commit(this.sortingQueryAction, {sorting: this.sortingQuery});
      this.$store.dispatch(this.getItemsAction)
    },1)
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted(){
    this.onMounted();
    this.setBorderlessTds();
  }

  openAddNewProductForm(id: string): void {
    this.$store.commit('investors/setNewProductInvestor',id);
    this.$router.push({name: 'newProduct'});
  }
}

export interface MarketplaceItem {
}

