var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "attachments-table"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "investorsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "items": _vm.tableItems,
      "busy": _vm.isBusy,
      "show-empty": ""
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(fileName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "name text-nowrap text-left"
        }, [_vm._v(_vm._s(rowData.item.fileName))])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.hasPreview(rowData.item) ? 'Preview' : '',
            expression: "hasPreview(rowData.item) ? 'Preview' : ''",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.openAttachmentPreview(rowData.item);
            }
          }
        }, [_c('img', {
          class: [!_vm.hasPreview(rowData.item) ? 'invisible' : ''],
          attrs: {
            "src": _vm._f("getIcon")('Fund')
          }
        })]), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Download',
            expression: "'Download'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.downloadFileByUrl(rowData.item);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOWNLOAD')
          }
        })]), _vm.showDelete ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Delete',
            expression: "'Delete'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action action--delete",
          on: {
            "click": function click($event) {
              return _vm.deleteAttachment(rowData.item.id);
            }
          }
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "15.605",
            "height": "18",
            "viewBox": "0 0 15.605 18"
          }
        }, [_c('path', {
          staticStyle: {
            "fill": "currentColor"
          },
          attrs: {
            "d": "M8.188.529a1.8 1.8 0 0 0-1.273.528l-.672.672H3.389a1.807 1.807 0 0 0-1.351.576 1.829 1.829 0 0 0-.453 1.224 1.83 1.83 0 0 0 .453 1.225 1.717 1.717 0 0 0 .749.48v1.895a.6.6 0 1 0 1.2 0V5.33h10.8v11.4a.586.586 0 0 1-.6.6h-9.6a.586.586 0 0 1-.6-.6v-6a.6.6 0 1 0-1.2 0v6a1.809 1.809 0 0 0 1.8 1.8h9.6a1.809 1.809 0 0 0 1.8-1.8V5.235a1.717 1.717 0 0 0 .75-.481 1.83 1.83 0 0 0 .453-1.225 1.829 1.829 0 0 0-.453-1.224 1.807 1.807 0 0 0-1.351-.576h-2.853l-.673-.672a1.8 1.8 0 0 0-1.272-.528zm0 1.2h2.4a.6.6 0 0 1 .424.176l.848.848a.6.6 0 0 0 .424.176h3.1a.533.533 0 0 1 .455.174.685.685 0 0 1 0 .853.533.533 0 0 1-.455.174h-12a.533.533 0 0 1-.455-.174.685.685 0 0 1 0-.853.533.533 0 0 1 .455-.174h3.1a.6.6 0 0 0 .424-.176l.848-.848a.6.6 0 0 1 .432-.176zM6.978 7.72a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609z",
            "transform": "translate(-1.585 -.529)"
          }
        })])]) : _vm._e()])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "no-attachments"
        }, [_vm._v("No attachments yet")])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1), _c('attachment-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAttachmentPreviewModal,
      expression: "showAttachmentPreviewModal"
    }],
    attrs: {
      "attachment": _vm.selectedAttachment
    },
    on: {
      "close": _vm.closeAttachmentPreviewModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }