





















import Vue from 'vue'
import Component, {mixins} from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import {SygniValidation} from "@/shared/mixins/ValidationMixin";
import IMask from 'imask';

// TODO: Make some description about how does it works.
// Currency format cant be used with number input
// Refactor this someday please
@Component({})
export default class SygniPlainInput extends mixins(Vue, SygniValidation) {
  @Prop({default: false}) required: boolean;
  @Prop({default: false}) inverted: boolean;
  @Prop() label!: string | number;
  @Prop() value!: string | number;
  @Watch('value', {immediate: true}) onValueChange() {
    if(!this.value) {
      this.modelValue = '';
      this.displayValue = '';
      return;
    }
    if(this.value !== this.modelValue){
      this.modelValue = this.value;
      this.displayValue = this.applyDisplayFormat(String(this.modelValue));
    }
  }
  @Watch('disabled', {immediate: true}) onDisabledChange() {
    this.isDisabled = this.disabled;
  }
  @Watch('mask') onMaskChange() {
    this.setInputMask();
  }
  @Prop({default: false}) error: boolean;
  @Prop({default: 'text'}) type: string;
  @Prop({default: false}) legacy: boolean;
  @Prop() unit!: string;
  @Prop() displayFormat!: string;
  @Prop({default: false}) showArrows: boolean;
  @Prop({default: false}) editable: boolean;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: false}) displayCheck: boolean;
  @Prop({default: false}) mask: boolean | string;
  @Prop({default: 'normal'}) unitFormat: 'normal' | 'simple';

  isDisabled: boolean = false;
  selectionStart: number = 0;
  selectionEnd: number = 0;
  maskInstance?: object = null;

  get sygniInputClassList(): string {
    let classList = '';
    if(this.isDisabled) classList += ' disabled';
    return classList;
  }

  modelValue: string | number = '';
  displayValue: string = '';

  @Emit('input')
  handleInput(event: any, value: string | number): string | number {
    const selectionStart = event ? event.target.selectionStart : false;
    const selectionEnd = event ? event.target.selectionEnd : false;

    let unformattedValue = this.unapplyDisplayFormat(value);
    if(this.displayFormat === 'currency' || this.displayFormat === 'number'){
      this.displayValue = String(value);
    } else {
      this.displayValue = this.applyDisplayFormat(String(value));
    }
    if(this.type === 'number' || this.displayFormat === 'currency' || this.displayFormat === 'number'){
      unformattedValue = Number(unformattedValue);
    } else {
      unformattedValue = String(unformattedValue);
    }
    this.modelValue = unformattedValue;

    if(selectionStart && selectionEnd) {
      this.selectionStart = selectionStart;
      this.selectionEnd = selectionEnd;
    } else {
      this.selectionStart = 0;
      this.selectionEnd = 0;
    }

    return unformattedValue;
  }

  enableInput() {
    this.isDisabled = false;
    this.$nextTick(() => {
      (this.$refs.sygniInputEl as HTMLInputElement).focus();
    });
  }

  removeKey(object: any, propName: string){
    return this.$options.filters.removeKey(object, propName);
  }

  setInputMask() {
    if (this.mask === 'iban') {
      this.$nextTick(() => {
        this.maskInstance = IMask(this.$refs.sygniInputEl as HTMLInputElement, {
              mask: '[aa]** **** **** **** **** **** **** ****'
            }
        );
      })
    } else {
      this.destroyInputMask();
    }
  }

  destroyInputMask() {
    (this.maskInstance as any)?.destroy();
  }

  mounted() {
    this.modelValue = this.value;
    this.isDisabled = this.disabled;
    this.setInputMask();
  }

  increment() {
    (this.modelValue as number)++;
    this.handleInput(null, this.modelValue);
  }

  decrement() {
    (this.modelValue as number)++;
    this.handleInput(null, this.modelValue);
  }

  applyDisplayFormat(value: string): string{
    if(!value) return '';
    if(this.displayFormat === 'currency'){
      return this.$options.filters.numberFormat(value ,2, true);
    }
    if(this.displayFormat === 'number') {
      const length = this.countDecimals(Number(value));
      return this.$options.filters.numberFormat(value, length > 1 ? length : 2, true);
    }
    return value;
  }

  unapplyDisplayFormat(value: any): any{
    if(this.displayFormat === 'currency'){
      return Number(String(value).replaceAll(' ', '').replaceAll(',','.'));
    } else if(this.displayFormat === 'number') {
      return Number(String(value).replaceAll(' ', '').replaceAll(',', '.'));
    }
    return value;
  }

  countDecimals(value: number) {
    if (Math.floor(value) === value) return 0;
    return value?.toString().split(".")[1]?.length || 0; 
  }

  onBlur(): void {
    this.$v?.$touch();
    if( this.displayFormat === 'currency'){
      this.displayValue = this.$options.filters.numberFormat(this.modelValue,2, true);
    }
    if( this.displayFormat === 'number') {
      const length = this.countDecimals(Number(this.modelValue));
      this.displayValue = this.$options.filters.numberFormat(this.modelValue, length > 1 ? length : 2, true);
    }
  }

  onSubmit(): void {
    this.$emit('submit');
  }
}
