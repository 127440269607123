var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-plain-input', _vm.error ? 'error' : '', _vm.inverted ? 'inverted' : '', _vm.legacy ? 'legacy' : '', _vm.sygniInputClassList]
  }, [_c('div', {
    staticClass: "input-container"
  }, [_c('input', _vm._g(_vm._b({
    ref: "sygniInputEl",
    class: _vm.unit ? 'with-unit' : '',
    attrs: {
      "disabled": _vm.isDisabled,
      "required": _vm.required
    },
    domProps: {
      "value": _vm.displayValue
    },
    on: {
      "input": function input($event) {
        return _vm.handleInput($event, $event.target.value);
      },
      "blur": _vm.onBlur,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, 'input', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.removeKey(Object.assign({}, _vm.$listeners), 'input'))), _vm.unit ? _c('div', {
    class: ['unit-type', _vm.unitFormat === 'simple' ? 'simple' : '']
  }, [_vm._v(" " + _vm._s(_vm.unit) + " ")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }