import { helpers } from "vuelidate/lib/validators";

export function phone (value: string) {
  if(!value) return true;
  return Boolean(value?.match(/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/));
}

// export const greaterThan = (comparisonValue: number) => (value: number) => {
//   return value > comparisonValue ? true : false
// }

export const greaterThan = (param: number) => helpers.withParams({ type: 'comparisonValue', comparisonValue: param }, (value: number) => value > param)

// export function iban(value: string) {
//   return isValidIBANNumber(value);
// }

export const hasCorrectAlready = (correctNumber: any, listOfReports: any) => (value: any) => {
  const result: boolean = listOfReports?.find((report: any) => report.correctionNumber === correctNumber) ? true : false

  return !(result)
}

export const iban = (country: string) => (value: string) => isValidIBANNumber(value, country);

/*
 * Returns 1 if the IBAN is valid 
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input: any, country: string) {
  if(input.value === '') return false;
  if(input.value !== undefined && !input.value) return true;

  input = input.value ? input.value : input;

  const containsCode = (/^[A-Za-z]+$/).test(input.substring(0, 1)) || (/^[A-Za-z]+$/).test(input.substring(1, 2));

  if(containsCode) {
    input = input.replace(/^.{2}/g, country);
  }

  const CODE_LENGTHS : any = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
    AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25, SV: 28,
    TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  };

  const iban: any = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code: any = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }

  // rearrange country code and check digits, and convert chars to ints
  const digits: any = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: any) => {
    return letter.charCodeAt(0) - 55;
  });

  // final check
  return mod97(digits) === 1;
}

function mod97(string: string) {
  let checksum: number | string = string.slice(0, 2), fragment;

  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
}