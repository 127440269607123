











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component({})
export default class SygniInfoBox extends Vue {
  @Prop({ default: '' }) content: string;
  @Prop({ default: 'normal' }) size: 'normal' | 'small'
}
