

































import { DocumentItem } from '@/modules/accounting/store/types';
import { FlagOption } from '@/modules/genprox/store/types';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from "vue-property-decorator";
import SygniLoader from '../layout/SygniLoader.vue';
import SygniCheckbox from './SygniCheckbox.vue';
import SygniFlag from '../layout/SygniFlag.vue';
import { BPopover } from 'bootstrap-vue';

@Component({
  components: { SygniCheckbox, SygniLoader, SygniFlag, BPopover }
})
export default class SygniFlagPicker extends Vue {
  @Prop() document: DocumentItem;
  showDropdown: boolean = false;
  isLoading: boolean = false;

  get selectedFlags(): Array<FlagOption> {
    return this.$store.getters['genprox/flags'].map((el: FlagOption) => {
      return {
        id: el.id,
        name: el.name,
        value: this.document?.flags?.includes(el.id) ? true : false,
        color: el.color
      }
    })
  }

  get selectedFlagsFiltered(): Array<FlagOption> {
    return this.selectedFlags.filter((el: FlagOption) => {
      return el.value
    });
  }

  get selectedFlagsToShow(): Array<FlagOption> {
    return this.selectedFlagsFiltered.slice(0, 3);
  }

  get counter() {
    return (this.selectedFlagsFiltered.length > 3) ? this.selectedFlagsFiltered.length - 3 : 0;
  }

  async toggleFlag(flag: FlagOption) {
    this.isLoading = true;

    try {
      const actionName = !(flag.value) ? 'postFlaggedObject' : 'deleteFlaggedObject';
      await this.$store.dispatch(`genprox/${actionName}`, {
        flagId: flag.id,
        objectId: this.document.id
      })
      flag.value = !(flag.value);
      this.$emit('changeFlag', flag.id);
      this.isLoading = false;
    } catch(e) {
      this.$emit('changeFlag');
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        type: 'error',
        title: 'An error occurred.',
        text: this.$t(errorMessage).toString()
      });
    }
  }
}
