var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-history-table"
  }, [_c('div', {
    staticClass: "product-history-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-inner-table sygni-b-table",
    attrs: {
      "id": "fe_changes_history_grid",
      "innertable": "true",
      "busy": _vm.isBusy,
      "tbody-class": "fe_changes_history_grid_tbody",
      "fields": _vm.tableFields,
      "show-empty": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("No history")])];
      },
      proxy: true
    }, {
      key: "cell(date)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "date text-left"
        }, [_vm._v(" " + _vm._s(_vm._f("dateWithTime")(rowData.item.date)) + " ")])];
      }
    }, {
      key: "cell(user)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "user"
        }, [_vm._v(" " + _vm._s(rowData.item.user) + " ")])];
      }
    }, {
      key: "cell(user2)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "user"
        }, [_vm._v(" " + _vm._s(rowData.item.user2) + " ")])];
      }
    }, {
      key: "cell(previousState)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(rowData.item.previousState) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm.getActionLabel(rowData.item.action)) + " ")];
      }
    }, {
      key: "cell(productStatus)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(rowData.item.productStatus.toUpperCase()) + " ")];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }