


























import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop, Watch,  } from 'vue-property-decorator';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInputGroup from '@/components/inputs/SygniInputGroup.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import PreviewDoc from '@/modules/accounting/components/PreviewDoc.vue';
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import { BACKEND_BASE_URL } from '@/shared/consts';
import { ProductSummaryIssuer, ProductSummaryInvestor } from '../../../models/ProductSummaryData.interface';
import { Product } from '../../../store/types';

@Component({
  components: { SygniLinkButton, SygniRoundedButton, SygniFileBox, SygniRectButton, SygniInputGroup, SygniSelect, SygniLoader, SygniModal, PreviewDoc, EditorComponent },
})
export default class AttachmentModal extends Vue {
  @Prop({ default: null }) attachment: any;
  @Prop({ default: null }) issuer: ProductSummaryIssuer;
  @Prop({ default: null }) investor: ProductSummaryInvestor;
  @Prop({ default: null }) product: Product | any;
  @Prop({ default: null }) investorDetails: any;
  @Prop({ default: null }) legalEntityInvestorData: any;
  @Prop({ default: false }) useCdn: boolean;
  @Prop({ default: 'normal' }) size: 'normal' | 'large'
  @Prop({ default: false }) download: boolean;
  
  agreementTemplateContent: string = '';
  isLoading: boolean = false;

  async loadAttachment() {
    this.isLoading = true;

    if(!this.isPdf) {
      const response = await this.$store.dispatch('templates/getTemplate', this.attachment.referenceId);
      this.agreementTemplateContent = response?.content;
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  }

  get isPdf() {
    return this.attachmentType == 'pdf';
  }

  get attachmentType(): 'pdf' | 'template' | null {
    if(this.attachment) {
      return this.attachment?.path || this.attachment?.filePath ? 'pdf' : 'template';
    }

    return null;
  }

  get attachmentPath() {
    if (this?.attachment?.path) {
      if (this.useCdn) {
        return this.attachment?.path;
      } else {
        return `${BACKEND_BASE_URL}${this.attachment?.path}`;
      }
    }
    
    if(this?.attachment?.filePath) {
      return `${BACKEND_BASE_URL}${this.attachment?.filePath}`;
    }

    return null;
  }

  @Watch('attachment', {deep: true}) async onCaseIdChange(): Promise<void> {
    this.loadAttachment();
    const appContentDiv: HTMLDivElement = this.$refs['attachmentModal'] as HTMLDivElement | undefined;
    appContentDiv?.querySelector('.attachment-modal__inner').scrollTo({top: 0});
  }
}
