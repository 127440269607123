var render = function () {
  var _vm$generatedClatTaxD, _vm$generatedClatTaxD2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "clat-tax-modal-wrapper"
  }, [_c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$generatedClatTaxD = _vm.generatedClatTaxDeclarations) !== null && _vm$generatedClatTaxD !== void 0 && _vm$generatedClatTaxD.length) && !_vm.preview,
      expression: "!generatedClatTaxDeclarations?.length && !preview"
    }],
    staticClass: "clat-tax-modal",
    attrs: {
      "isLoading": _vm.isClatTaxModalLoading,
      "overflow": false,
      "header": "Clat TAX Declaration",
      "description": "In order to generate declaration fill in the fields below.",
      "cancelText": "Cancel",
      "confirmText": "Generate"
    },
    on: {
      "close": _vm.closeClatTaxModal,
      "cancel": _vm.closeClatTaxModal,
      "confirm": _vm.generateClatTaxDeclaration
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.taxOfficeCodes,
            "label": "Select tax number"
          },
          model: {
            value: _vm.clatTaxFormData.taxOfficeCode,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxFormData, "taxOfficeCode", $$v);
            },
            expression: "clatTaxFormData.taxOfficeCode"
          }
        }), _c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "label": "Is correction?"
          },
          model: {
            value: _vm.clatTaxFormData.correction,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxFormData, "correction", $$v);
            },
            expression: "clatTaxFormData.correction"
          }
        })];
      },
      proxy: true
    }])
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm$generatedClatTaxD2 = _vm.generatedClatTaxDeclarations) === null || _vm$generatedClatTaxD2 === void 0 ? void 0 : _vm$generatedClatTaxD2.length,
      expression: "generatedClatTaxDeclarations?.length"
    }],
    staticClass: "clat-tax-declarations-modal",
    attrs: {
      "header": _vm.declarationsHeading,
      "size": "large",
      "description": _vm.declarationsDescription,
      "cancelText": "Close",
      "confirmText": "Download all"
    },
    on: {
      "cancel": _vm.closeClatTaxModal,
      "close": _vm.closeClatTaxModal,
      "confirm": _vm.downloadAllDeclarations
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "clatTaxDeclationsTable",
          staticClass: "sygni-b-inner-table",
          attrs: {
            "innertable": "true",
            "fields": [{
              key: 'fileName',
              sortable: false,
              class: ['file-name']
            }, {
              key: 'actions',
              sortable: false,
              class: ['actions'],
              label: 'Action'
            }],
            "items": _vm.generatedClatTaxDeclarations
          },
          scopedSlots: _vm._u([{
            key: "cell(fileName)",
            fn: function fn(rowData) {
              var _rowData$item, _rowData$item2;

              return [_c('div', [_vm._v(_vm._s(rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.fileName ? rowData === null || rowData === void 0 ? void 0 : (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.fileName : ''))])];
            }
          }, {
            key: "cell(actions)",
            fn: function fn(rowData) {
              return [_c('div', {
                staticClass: "status"
              }, [_c('div', {
                staticClass: "table__status d-flex justify-content-center align-items-center"
              }, [_c('sygni-rounded-button', {
                class: ['outline', 'gn-secondary'],
                on: {
                  "click": function click($event) {
                    return _vm.downloadFileByUrl(rowData === null || rowData === void 0 ? void 0 : rowData.item);
                  }
                }
              }, [_vm._v("Download")])], 1)])];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }